import React from "react";

interface MenuIconProps {
  fill?: string;
  width?: number;
}

const MenuIcon = ({ fill, width }: MenuIconProps) => {
  const userStyles: any = {};
  fill && (userStyles.fill = fill);
  width && (userStyles.width = width);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={userStyles}
    >
      <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"></path>
    </svg>
  );
};
export default MenuIcon;

import "./Transition.style.scss";
import React from "react";
import {
  Transition as ReactTransition,
  TransitionGroup,
} from "react-transition-group";

// So the total duration will be _twice_ this
const timeout = 1200;

const styles: any = {
  entering: {},
  entered: {},
  exiting: {},
};

const Transition = ({ children, location }: any) => (
  <TransitionGroup className={`transition-group-wrap`}>
    <ReactTransition key={location.pathname} timeout={timeout}>
      {(status) => (
        <>
          <div
            className={`mr-white ${
              status === "exiting" ? "mr-white-leaving" : ""
            }`}
          ></div>
          <div className={`transition-inner-wrap ${status}`}>{children}</div>
        </>
      )}
    </ReactTransition>
  </TransitionGroup>
);

export default Transition;

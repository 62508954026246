import React from "react";

interface CVIconProps {
  fill?: string;
  width?: number;
}

const CVIcon = ({ fill, width }: CVIconProps) => {
  const userStyles: any = {};
  fill && (userStyles.fill = fill);
  width && (userStyles.width = width);

  return (
    <svg
      enableBackground="new 0 0 496.049 496.049"
      height="22"
      viewBox="0 0 496.049 496.049"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      style={userStyles}
    >
      <path d="m389.157 0h-282c-26.468 0-48 21.533-48 48 0 358.314-1.942 338.722 4.692 345.319 105.735 105.734 99.331 100.377 104.671 101.993 3.346 1.012-10.648.688 220.637.688 26.468 0 48-21.533 48-48v-400c0-26.467-21.532-48-48-48zm-232 441.373-43.373-43.373h43.373zm248 6.627c0 8.822-7.178 16-16 16h-200v-82c0-8.836-7.163-16-16-16h-82v-318c0-8.822 7.178-16 16-16h282c8.822 0 16 7.178 16 16zm-51-132c0 8.836-7.163 16-16 16h-181c-8.837 0-16-7.164-16-16s7.163-16 16-16h181c8.837 0 16 7.164 16 16zm0-82c0 8.836-7.163 16-16 16h-181c-8.837 0-16-7.164-16-16s7.163-16 16-16h181c8.837 0 16 7.164 16 16zm0 164c0 8.836-7.163 16-16 16h-103c-8.837 0-16-7.164-16-16s7.163-16 16-16h103c8.837 0 16 7.164 16 16zm-164.583-259.226c-6.249-6.248-6.249-16.379 0-22.627 6.248-6.248 16.379-6.248 22.627 0l21.226 21.226 67.187-67.186c6.248-6.249 16.379-6.249 22.627 0 6.249 6.248 6.249 16.379 0 22.627l-78.5 78.5c-6.247 6.248-16.379 6.249-22.627 0z"></path>
    </svg>
  );
};
export default CVIcon;

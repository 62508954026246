import "./BottomNav.style.scss";
import { Link } from "gatsby";
import React from "react";
import AboutIcon from "../../../assets/icons/About";
import ContactIcon from "../../../assets/icons/Contact";
import HomeIcon from "../../../assets/icons/Home";
import PortfolioIcon from "../../../assets/icons/Portfolio";
import SkillsIcon from "../../../assets/icons/Skills";
import * as styles from "./BottomNav.module.scss";

const BottomNav = () => {
  return (
    <nav className={`hide-desktop ${styles.nav}`}>
      <Link
        activeClassName="bottom-nav-link-active"
        activeStyle={{ backgroundColor: "#202428" }}
        className={`bottom-nav-link ${styles.navLink}`}
        to="/"
      >
        <HomeIcon fill={`#fff`} width={20} />
        <span>{`Home`}</span>
      </Link>
      <Link
        activeClassName="bottom-nav-link-active"
        className={`bottom-nav-link ${styles.navLink}`}
        to="/about"
      >
        <AboutIcon fill={`#fff`} width={20} />
        <span>{`About`}</span>
      </Link>
      <Link
        activeClassName="bottom-nav-link-active"
        className={`bottom-nav-link ${styles.navLink}`}
        to="/skills"
      >
        <SkillsIcon fill={`#fff`} width={20} />
        <span>{`Skills`}</span>
      </Link>
      <Link
        activeClassName="bottom-nav-link-active"
        className={`bottom-nav-link ${styles.navLink}`}
        to="/portfolio"
      >
        <PortfolioIcon width={25} fill={`#fff`} />
        <span>{`Portfolio`}</span>
      </Link>
      <Link
        activeClassName="bottom-nav-link-active"
        className={`bottom-nav-link ${styles.navLink}`}
        to="/contact"
      >
        <ContactIcon fill={`#fff`} width={20} />
        <span>{`Contact`}</span>
      </Link>
    </nav>
  );
};

export default BottomNav;

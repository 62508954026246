import "./SideNav.style.scss";
import { Link } from "gatsby";
import React from "react";
import AboutIcon from "../../../assets/icons/About";
import CertificatesIcon from "../../../assets/icons/Certificates";
import ContactIcon from "../../../assets/icons/Contact";
import CVIcon from "../../../assets/icons/CV";
import GithubIcon from "../../../assets/icons/Github";
import HomeIcon from "../../../assets/icons/Home";
import LinkedInIcon from "../../../assets/icons/LinkedIn";
import LogoIcon from "../../../assets/icons/Logo";
import PortfolioIcon from "../../../assets/icons/Portfolio";
import SkillsIcon from "../../../assets/icons/Skills";
import * as styles from "./SideNav.module.scss";

const SideNav = () => {
  const NavLinkActivated = ({
    to,
    title,
    children,
  }: {
    to: string;
    title: string;
    children: any;
  }) => {
    return (
      <Link
        activeClassName="nav-link-active"
        activeStyle={{ backgroundColor: "#202428" }}
        className={`nav-link ${styles.navLink}`}
        to={to}
      >
        {children}
        <span className="nav-link-title">{title}</span>
      </Link>
    );
  };

  return (
    <header className={`hide-mobile ${styles.header}`}>
      <Link
        className={`header-logo ${styles.logo}`}
        to="/"
        title="Front End Web Developer: Merianos Ioannis"
      >
        <LogoIcon width={35} fill={`#9193a8`} />
      </Link>
      <nav className={`nav-nav ${styles.nav}`}>
        <NavLinkActivated to="/" title="Home">
          <HomeIcon fill={`#9193a8`} />
        </NavLinkActivated>
        <NavLinkActivated to="/about" title="About">
          <AboutIcon fill={`#9193a8`} />
        </NavLinkActivated>
        <NavLinkActivated to="/skills" title="Skills">
          <SkillsIcon fill={`#9193a8`} />
        </NavLinkActivated>
        <NavLinkActivated to="/portfolio" title="Portfolio">
          <PortfolioIcon width={25} fill={`#9193a8`} />
        </NavLinkActivated>
        <NavLinkActivated to="/certificates" title="Certificates">
          <CertificatesIcon width={25} fill={`#9193a8`} />
        </NavLinkActivated>
        <NavLinkActivated to="/online-cv" title="Online CV">
          <CVIcon width={25} fill={`#9193a8`} />
        </NavLinkActivated>
        <NavLinkActivated to="/contact" title="Contact">
          <ContactIcon fill={`#9193a8`} />
        </NavLinkActivated>
      </nav>
      <ul className={`${styles.socialList}`}>
        <li>
          <a
            className={`social-link ${styles.socialLink}`}
            href="https://github.com/ioanmeri"
            target="_blank"
            aria-label="Ioannis Merianos Profile on Github"
          >
            <GithubIcon fill={`#9193a8`} height={22} width={22} />
          </a>
        </li>
        <li>
          <a
            className={`social-link ${styles.socialLink}`}
            href="https://gr.linkedin.com/in/ioannis-merianos-528429142"
            target="_blank"
            aria-label="Ioannis Merianos Profile on Linkedin"
          >
            <LinkedInIcon fill={`#9193a8`} width={20} />
          </a>
        </li>
      </ul>
    </header>
  );
};

export default SideNav;

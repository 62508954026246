import { Link } from "gatsby";
import React from "react";
import LogoIcon from "../../../assets/icons/Logo";
import MenuIcon from "../../../assets/icons/Menu";
import * as styles from "./MobileHeader.module.scss";

const MobileHeader = () => {
  return (
    <header className={`hide-desktop ${styles.header}`}>
      <Link
        to="/"
        className={styles.linkLogo}
        title="Front End Web Developer: Merianos Ioannis"
      >
        <LogoIcon fill="#fff" width={40} />
      </Link>
      <Link
        to="/menu"
        className={styles.linkMenu}
        aria-label="Discover all the menu links"
      >
        <MenuIcon fill="#fff" />
      </Link>
    </header>
  );
};

export default MobileHeader;

import React from "react";

interface LogoIconProps {
  className?: string;
  fill?: string;
  width?: number;
}

const LogoIcon = ({ fill, width, className }: LogoIconProps) => {
  const userStyles: any = {};
  fill && (userStyles.fill = fill);
  width && (userStyles.width = width);

  return (
    <>
      <svg
        className={className}
        id="Layer_1"
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 398"
        style={userStyles}
      >
        <polygon points="227.800 0.000, 301.680 122.780, 248.690 215.190, 227.800 182.910, 106.280 396.360, 0.000 394.940, 227.800 0.000"></polygon>{" "}
        <polygon points="533.990 397.870, 410.960 183.230, 320.220 341.540, 267.290 249.590, 410.960 0.000, 640.000 397.870, 640.000 397.870, 533.990 397.870"></polygon>
      </svg>
    </>
  );
};
export default LogoIcon;

import React, { useEffect } from "react";
import DesktopSideNav from "../components/_desktop/DesktopSideNav/SideNav";
import MobileBottomNav from "../components/_mobile/MobileBottomNav/BottomNav";
import MobileHeader from "../components/_mobile/MobileHeader/MobileHeader";
import * as styles from "../components/layouts/MainLayout/MainLayout.module.scss";
import Transition from "../components/Transition";

interface MainLayoutProps {
  children: any;
  location: any;
}

const ScrollToTopAndFade = ({ location, children }: any) => {
  useEffect(() => {
    let el1 = document.getElementById("main-mobile");
    if (el1) {
      el1.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className={"fade-in-page"} id="mobile-wrap">
      {children}
    </div>
  );
};

const MainLayout = ({ children, location }: MainLayoutProps) => {
  return (
    <div id="page-layout" className={styles.layout}>
      <div className="background_stars">
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars_blink_1"></div>
        {/* <div id="stars_blink_2"></div> */}
      </div>
      <MobileHeader />
      <DesktopSideNav />
      <main className={`hide-mobile ${styles.main}`}>
        <Transition location={location}>{children}</Transition>
      </main>
      <main id="main-mobile" className={`hide-desktop ${styles.main}`}>
        <ScrollToTopAndFade location={location}>{children}</ScrollToTopAndFade>
      </main>
      <MobileBottomNav />
    </div>
  );
};

export default MainLayout;
